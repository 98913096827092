import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {  Router } from '@angular/router';
import { timingSafeEqual } from 'crypto';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CountService } from '../service/count.service';
declare var bootstrap: any;
@Component({
  selector: 'app-orders-page',
  templateUrl: './orders-page.component.html',
  styleUrls: ['./orders-page.component.css'],
})
export class OrdersPageComponent implements OnInit {
  mobileData:any ="orderpage-mobile";
  selectedMenuItem: string = 'Orders';
  userId!: string ;
  bookedCount:any | undefined;
  inProgressCount:any | undefined;
  readyCount:any | undefined;
  totalCount:any | undefined;
  @Output() menuItemClicked = new EventEmitter<string>();
  isMobile: any;
  orderPageSubscription: Subscription | null = null;

  constructor(public dialog: MatDialog,private router: Router,private http: HttpClient, public auth: AuthService,public countservice: CountService) {
  this.auth.userInfo.subscribe((info:any) =>{
        this.userId = info.id;
    })
  }


  @HostListener('window:resize', [])
  onResize(): void {

    this.onMenuItemSelected('')
    this.isMobile  = window.innerWidth < 993; // Update on resize
  }

  ngOnInit() {
    this.isMobile  = window.innerWidth < 993;
    this.countservice.bookedCount$.subscribe((count) => {
      this.bookedCount = count;
    });

    this.countservice.progressCountSubject$.subscribe((count) => {
      this.inProgressCount = count;
    });

    this.countservice.readyCountSubject$.subscribe((count) => {
      this.readyCount = count;
    });

    this.countservice.totalCountSubject$.subscribe((count) => {
      this.totalCount = count;
    });

    this.http.post(environment.apiUrl + `/vendororders/order-count`,{ isCurrentDate: true, orderStatus: "booked",vendorId: this.userId},{ responseType:'json' }).subscribe( (data:any)=>{
       if(data.status === "SUCCESS"){
          this.bookedCount = data.count.toString().padStart(2, '0');
        }
     })

    this.http.post(environment.apiUrl + `/vendororders/order-count`,{ isCurrentDate: true,  orderStatus: "in_progress",vendorId: this.userId},{responseType:'json'}).subscribe( (data:any)=>{
      if(data.status === "SUCCESS"){
          this.inProgressCount = data.count.toString().padStart(2, '0');
        }
    })

    this.http.post(environment.apiUrl + `/vendororders/order-count`,{  isCurrentDate: true,  orderStatus: "ready",vendorId: this.userId},{ responseType:'json' }).subscribe( (data:any)=>{
       if(data.status === "SUCCESS"){
          this.readyCount = data.count.toString().padStart(2, '0');
        }
    })

    this.http.post(environment.apiUrl + `/vendororders/order-count`,{  orderStatus: "ready",  vendorId:  this.userId },{ responseType:'json' }).subscribe( (data:any)=>{
      if(data.status === "SUCCESS"){
        this.totalCount = data.count.toString().padStart(2, '0');
      }
    })                        
  }

  onMenuItemSelected(menuItem: any): void {
    this.selectedMenuItem = menuItem;
    switch (menuItem) {
      case 'Orders':
        this.router.navigate(['/orders-page/new-orders']);
        break;
      case 'FoodItems':
        this.router.navigate(['/orders-page/food-items-page']);
        break;
      case 'Financial':
        this.router.navigate(['/orders-page/financial-page']);
        break;
      case 'History':
        this.router.navigate(['/orders-page/history-page']);
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    
    if (this.orderPageSubscription) {
      this.orderPageSubscription.unsubscribe();
    }
  }
}
