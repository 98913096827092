import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountService {

  private bookedCountSubject = new BehaviorSubject<string>('00');
  bookedCount$ = this.bookedCountSubject.asObservable();
  private progressCountSubject = new BehaviorSubject<string>('00');
  progressCountSubject$ = this.progressCountSubject.asObservable();
  private readyCountSubject = new BehaviorSubject<string>('00');
  readyCountSubject$ = this.readyCountSubject.asObservable();
  private totalCountSubject = new BehaviorSubject<string>('00');
  totalCountSubject$ = this.totalCountSubject.asObservable();

  updateBookedCount(newCount: any) {
    this.bookedCountSubject.next(newCount);
  }
  updateprogressCount(newCount: any) {
    this.progressCountSubject.next(newCount);
  }
  updatereadyCount(newCount: any) {
    this.readyCountSubject.next(newCount);
  }
  updatetotalCount(newCount: any) {
    this.totalCountSubject.next(newCount);
  }
}
