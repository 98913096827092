import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { DatefotmatService } from '../service/datefotmat.service';
declare var $: any;
declare var bootstrap: any;

declare global {
  interface JQuery {
    datepicker(options?: any): JQuery;
    datepicker(method: string): JQuery;
    datepicker(method: string, option: any): JQuery;
  }
}
export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css'],
})
export class HistoryComponent implements OnInit {
  showShadow: boolean = false;
  showSize: boolean = true;
  showSort: boolean = false;
  rangeDates: Date[] = [];
  date!: Date;
  inputFieldValue: string = '';
  startDateValue: string = '';
  endDateValue: string = '';
  maxDate: Date;
  today: Date = new Date();
  calendarStyles: { [key: string]: string } = {};
  isInline: boolean = window.innerWidth > 768;
  userId: string | null =null;
  completeData :any[]=[];
  completeOrder: any;
  historySubscription: Subscription | null = null;
  currentDate:any;
 
  constructor(private cdr: ChangeDetectorRef,private el: ElementRef,private dateService: DatefotmatService,private http: HttpClient, public auth: AuthService,) {
    this.maxDate = new Date();
    // this.renderer.setStyle(document.body, 'overflow', 'auto');
    this.auth.userInfo.subscribe((info:any) =>{
      this.userId = info.id
    })
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.currentDate = this.dateService.formatDate(new Date());
    this.endDateValue = this.currentDate;
    // this.cdr.detectChanges();
    
     this.http.post(environment.apiUrl + `/vendororders/order-list`,{ vendorId: this.userId,orderStatus: "ready",  endDate:new Date().getTime()},{responseType:'json'}).subscribe( (data:any)=>{
      if(data.status === "SUCCESS"){
        this.completeData = data.orders_output.sort((a: any, b: any) => b.ordernumber - a.ordernumber);
       }
    })
  }
  // get displayDate(): string {
  //   return this.endDateValue || this.currentDate; // Return endDateValue if it's set, otherwise currentDate
  // }

  // // Setter for displayDate
  // set displayDate(value: string) {
  //   this.endDateValue = value; // Update _endDateValue when the input changes
  // }
  updateStartDate(value: any): void {
    this.startDateValue = value; // Update the start date
  }

  updateEndDate(value: any): void {
    this.endDateValue = value; // Update the end date
  }
  toggleDropdown() {
    const sortButton = document.getElementById('sortButton');
    const dropdownMenu = document.getElementById('dropdownMenu');

    if (sortButton && dropdownMenu) {
      if (sortButton.style.display === 'none') {
        // If the dropdown is shown, hide it and show the button
        sortButton.style.display = 'inline-block';
        dropdownMenu.style.display = 'none';
      } else {
        // If the button is shown, hide the button and show the dropdown
        sortButton.style.display = 'none';
        dropdownMenu.style.display = 'block';
      }
    } else {
      console.error('Sort button or dropdown menu element not found');
    }
  }

  ngAfterViewInit(): void {}
  onClose() {
    // this.Display = 'new-order';
  }
  updateDate(rangeDates:any) {
    
    // Clear previous values
    this.startDateValue = '';
    this.endDateValue = '';

    // Set start and end date values
    if (this.rangeDates.length > 0 && this.rangeDates[0]) {
      const startTimestamp = this.normalizeToLocalDate(this.rangeDates[0]);
      this.startDateValue = this.dateService.formatDate(new Date(startTimestamp!));
    }
    if (this.rangeDates.length === 2 && this.rangeDates[1]) {
      const endTimestamp = this.normalizeToLocalDate(this.rangeDates[1], true); 
      this.endDateValue = this.dateService.formatDate(new Date(endTimestamp!));

    }
   
    
    // Normalize start and end dates
    const startTimestamp = this.normalizeToLocalDate(this.rangeDates[0]); // Midnight
    const endTimestamp = this.normalizeToLocalDate(this.rangeDates[1], true); 
    

    this.http.post(environment.apiUrl + `/vendororders/order-list`,{ vendorId: this.userId,orderStatus: "ready", startDate:startTimestamp, endDate:endTimestamp},{responseType:'json'}).subscribe( (data:any)=>{
      if(data.status === "SUCCESS"){
        this.completeData = data.orders_output;
       }
    })

  }
   normalizeToLocalDate = (date: Date | null, endOfDay: boolean = false): number | null => {
    if (!date) return null; // Handle null or undefined date
    const normalizedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    if (endOfDay) {
      normalizedDate.setHours(23, 59, 59, 999); // Set to 11:59:59.999 PM
    }
    return normalizedDate.getTime();
  };

  toDate(date: any): Date {
    return new Date(date.year, date.month , date.day); // Month is 0-indexed in JavaScript
  }
  
  // Check if the date is the start of the range
  isStartDate(date: any): boolean {
    if (!this.rangeDates || !this.rangeDates[0]) {
      return false;
    }
    return this.toDate(date).getTime() === this.rangeDates[0].getTime();
  }
  
  // Check if the date is the end of the range
  isEndDate(date: any): boolean {
    if (!this.rangeDates || !this.rangeDates[1]) {
      return false;
    }
    return this.toDate(date).getTime() === this.rangeDates[1].getTime();
  }
  
  // Check if the date is between the start and end dates
  isInRange(date: any): boolean {
    if (!this.rangeDates || !this.rangeDates[0] || !this.rangeDates[1]) {
      return false;
    }
    const currentDate = this.toDate(date).getTime();
    return currentDate > this.rangeDates[0].getTime() && currentDate < this.rangeDates[1].getTime();
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.isInline = window.innerWidth > 768; // Switch between inline and popup
    this.updateCalendarStyles();
  }

  updateCalendarStyles(): void {
    if (window.innerWidth >= 768) {
    //   this.calendarStyles = { width: '100%', fontSize: '14px' }; // Example styles for smaller screens
    // } else {
      this.calendarStyles = { width: 'auto', fontSize: '16px' }; // Example styles for larger screens
    }
  }

  completelist(order:any){
    this.completeOrder = order;
    this.http.get(environment.apiUrl +`/vendororders/order-get?id=${order.id}&vendorId=${this.userId}`).subscribe((data: any) => {
        this.completeOrder = data.orders;
      });
  }

  historyoffset(): void {
    const offcanvas = document.getElementById('historyId');
    if (offcanvas) {
      const bsOffcanvas = new bootstrap.Offcanvas(offcanvas);
      bsOffcanvas.show();
    }
    // Ensure only one backdrop exists
    const backdrops = document.querySelectorAll('.offcanvas-backdrop');
    if (backdrops.length > 1) {
      for (let i = 1; i < backdrops.length; i++) {
        backdrops[i].remove();
      }
    }
  }
  
  ngOnDestroy() {
    // Ensure subscription is cleaned up
    if (this.historySubscription) {
      this.historySubscription.unsubscribe();
    }
}
}