<div class="d-lg-block d-none mt-4 food-items">
  <div class="row ">
    <div class="text-start col-6">
      <div class="order-title me-3 px-3 pt-2" (click)="onStockSelected('inStock')"
        [class.selectedStock]="currentstockBar === 'inStock'"
        [ngClass]="{'stock-title': currentstockBar === 'inStock'}">Available items</div>
      <div class="order-title me-3 px-3 pt-2" (click)="onStockSelected('outofStock')"
        [class.selectedStock]="currentstockBar === 'outofStock'"
        [ngClass]="{'stock-title': currentstockBar === 'outofStock'}">Unavailable items</div>
    </div>
  </div>
</div>
<div class="d-lg-none d-block mt-4">
  <div class="text-start d-flex justify-content-between ">
    <div class="order-title me-3 px-3 pt-2" (click)="onStockSelected('inStock')"
      [class.selectedStock]="currentstockBar === 'inStock'" [ngClass]="{'stock-title': currentstockBar === 'inStock'}">
      Available items</div>
    <div class="order-title me-3 px-3 pt-2" (click)="onStockSelected('outofStock')"
      [class.selectedStock]="currentstockBar === 'outofStock'"
      [ngClass]="{'stock-title': currentstockBar === 'outofStock'}">Unavailable items</div>
  </div>
</div>
<!-- order-details table -->
<div class="border"></div>
<div class=" row px-3 pt-4">
  <div class="items mt-2 col-6 px-0">
    <small>Total food items :</small>
    <small class="ms-2">{{ currentstockBar === 'inStock' ? availableItems.length : unavailableItems.length }}</small>
  </div>
  <div class="  col-6 seach-place px-0">
    <div class="search-container mx-0">
      <input type="text" placeholder="Search  Foods items" class="text-start" [(ngModel)]="searchQuery"  (input)="onSearch()">
    <button type="submit" (click)="onSearch()"><i class="fa fa-search"></i></button>
    </div>
  </div>
</div>

<div class="mt-4 row  show-stock" *ngIf="currentstockBar === 'inStock'">
  <div class="col-6 col-md-3  text-center py-md-3 py-2" *ngFor="let data of availableItems">
    <img [src]="data.src[0]" class="w-30 " alt="stock" type="button" data-bs-toggle="offcanvas"
      data-bs-target="#new-stockId" aria-controls="offcanvasRight" (click)="onItemClick(data);fooditemoffset()">
    <div class="my-3 stock-name"> {{data.title}}</div>
  </div>
</div>

<div>
  <div class="mt-4 row  show-stock" *ngIf="currentstockBar === 'outofStock'">
    <div class="col-6 col-md-3 text-center py-md-3 py-2" *ngFor="let data of unavailableItems">
      <img [src]="data.src[0]" class="w-30 outof-stock" alt="stock" type="button" data-bs-toggle="offcanvas"
        data-bs-target="#new-stockId" aria-controls="offcanvasRight" (click)="onItemClick(data);fooditemoffset()">
      <div class="my-3 stock-name"> {{data.title}}</div>
    </div>
  </div>
</div>

<!-- canvas -->
<div class="offcanvas offcanvas-end p-3 pt-0" tabindex="-1" id="new-stockId" aria-labelledby="offcanvasRightLabel" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="offcanvas-header">
    <div class="d-flex">
      <div class="text-center  product-details  stockname" id="offcanvasRightLabel">{{ selectedItem?.title }}</div>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" (click)="onClose()"></button>
  </div>
  <div class="border-style"></div>
  <div class="offcanvas-body py-0">
    <div *ngIf="selectedItem?.src" class="p-4 ">
      <img [src]="selectedItem?.src[0]" class="w-100  stock-img" alt="stock" *ngIf="currentstockBar !== 'outofStock'">
      <img [src]="selectedItem?.src[0]" class="w-100  outof-stock stock-img" alt="stock"
        *ngIf="currentstockBar === 'outofStock'">
    </div>
    <div class="text-center stock-color"> {{ selectedItem?.title }}</div>
    <div class="text-center stock-price mt-2"><span class="currency-symbol"></span> {{ selectedItem?.minprice | number:'1.2-2' }}</div>

    <div class="mt-5" *ngIf="currentstockBar === 'inStock'">
      <button type="submit" class="btn d-block m-auto out-of-order default w-100 py-2"
        (click)="onAvailable(selectedItem?.id)" data-bs-dismiss="offcanvas" aria-label="Close">Marked as
        unavailable</button>
    </div>
    <div class="mt-4" *ngIf="currentstockBar === 'outofStock'">
      <button type="submit" class="btn d-block m-auto out-of-order default w-100 py-2"
        (click)="onUnavailable(selectedItem?.id)" data-bs-dismiss="offcanvas" aria-label="Close">Marked as
        available</button>
    </div>
  </div>


</div>