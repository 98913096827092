import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, map, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
export interface loginResponse {
  locations: any;
  token: string,
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient,private router: Router) {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      this.accessToken.next(accessToken);
      this.userInfo.next(this.decodeToken(accessToken));
    }
//     // login
//     const sessionExpired = localStorage.getItem('sessionExpired');
// //  this.accessToken.next(localStorage.getItem('accessToken') || null);
//     let accessToken = localStorage.getItem('accessToken')  || null;

//     if (accessToken && accessToken !== "undefined" && !sessionExpired) {
//       this.userInfo.next(this.decodeToken(accessToken));
//     }

//     this.UserType.next(localStorage.getItem('UserType') || null);
    
  }
// login
searchKey:string =''
public accessToken: BehaviorSubject<any> = new BehaviorSubject(null);
public userName: BehaviorSubject<any>= new BehaviorSubject(null);
userInfo: BehaviorSubject<any> = new BehaviorSubject(null);
UserType: BehaviorSubject<any> = new BehaviorSubject(null);
UserAction: BehaviorSubject<any> = new BehaviorSubject(null);
isSubmitting = false;


decodeToken(token: string): any {
  return jwtDecode(token);
}
	
login(credentials: { emailOrphone: string, password: string,rememberme:boolean}) {
  let data:any = credentials;
  if (localStorage.getItem('appToken')) {
    data.appToken = localStorage.getItem('appToken');
  }
  return this.http.post<loginResponse>(environment.apiUrl + '/users/login',data, { responseType: 'json' }).pipe(
    tap((data: any) => {
      if(data.status === "SUCCESS"){
        const token = data.token;
        const decodedToken = this.decodeToken(token);
        localStorage.setItem('accessToken', token);
        localStorage.setItem('UserType',"vendor");

        this.accessToken.next(token);
        this.userInfo.next(this.decodeToken(data.token));
        
        this.userName.next(decodedToken.firstname);
        this.UserAction.next(data.action);
       console.log(this.UserAction,"this.UserAction")
      }

    }),map((data: any) => {
      return data;
  }));
}
logout() {
  localStorage.clear();
  this.userName.next(null);
  this.accessToken.next(null);
  // this.userInfo.next(null);
  this.UserType.next(null);
 this.UserAction.next(null);
  console.log(  this.userName,
  this.accessToken,
  this.userInfo,
  this.UserType, this.UserAction,"kk")
  this.router.navigate(['/login']);
}
}
