import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  sessionExpired = false;
  constructor(private router: Router,private authService :AuthService,private messageService: MessageService) {
   
    
  }
  canActivate(): boolean {
    const accessToken = localStorage.getItem('accessToken');
  
    if (accessToken) {
      return true;
    } else {
      if (!this.sessionExpired) {
        this.sessionExpired = true;
        localStorage.removeItem('accessToken');
        localStorage.removeItem('UserType');
        localStorage.removeItem('userName');
        localStorage.removeItem('userId');
        localStorage.setItem('sessionExpired', 'true');
        // this.messageService.add({ severity: 'warn', summary: 'Session Expired', detail: 'Please log in again.', sticky: true });
      }
      this.router.navigate(['/login']);
      return false;
    }
  }
  
}
